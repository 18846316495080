@import '../styles/Colors.scss';

.product-cell {
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: space-between;
    box-shadow: 3px 5px 15px rgba(22, 22, 22, 0.3);
    background-color: rgb(245, 245, 245);
    min-height: 500px;
    padding: 15px;

    .cell-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 275px;


        @media only screen and (max-width: 1024px) {
            width: calc(100vw - 30px);
            margin: 0px;
        }
    }

    .footer {
        display: flex;
        gap: 15px;

        .quick-add-quantity {
            flex-grow: 1;
        }
    }

    hr {
        width: 90%;
        border-color: rgba(22, 22, 22, 0.05);
        margin: 0px;
    }

    .thumbnail {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        margin: 15px;
        cursor: pointer;
        overflow: hidden;
        width: calc(100% - 30px);
        text-align: center;

        img {
            height: 245px;
        }
    }

    .info {
        padding-top: 15px;
        width: calc(100% - 30px);

        .title {
            font-size: 1.2em;
            font-weight: bold;
            margin-bottom: 10px;
            cursor: pointer;
            transition: color 0.2s;

            &:hover {
                color: $red-2;
            }
        }

        .description {
            font-size: 0.8em;
            margin-bottom: 10px;
        }

        .inventory {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .price {
                font-size: 1.2em;
                margin-bottom: 7px;

                .label {
                    font-size: 0.7em;
                }
            }

            .available {
                display: flex;
                align-items: flex-end;

                // .value {
                //     font-size: 1.3em;
                // }

                .label {
                    margin-left: 5px;
                }
            }
        }
    }
}