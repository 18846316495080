@import './Colors.scss';

.rental-date-navbar {
    width: 15vw;
    display: flex;
    flex-direction: column;
    padding-left: 15px;
    padding-right: 15px;

    @media only screen and (max-width: 1024px) {
        padding-left: 0px;
        padding-right: 0px;
        width: 100%;
    }

    .mobile-navbar {
        background-color: $red-2;
        color: $white;
        padding: 10px;
        position: fixed;
        top: 50px;
        z-index: 50;
        text-align: center;
        width: 100%;
        display: flex;
        justify-content: center;
        opacity: 0.9;

        span {
            margin-right: 3px;
        }

        .mobile-chevron {
            font-size: 1.5em;
            margin-top: -3px;
            transition: transform 0.5s;
            transform: rotate(0deg);

            &.expanded {
                transform: rotate(180deg);
            }
        }
    }

    .content {
        padding-left: 30px;
        padding-right: 30px;
        width: calc(100% - 60px);
        overflow-y: hidden;

        @media only screen and (max-width: 1024px) {
            margin-top: 15px;
            position: fixed;
            top: 80px;
            background-color: $white;
            z-index: 49;
            transform: scaleY(0%);
            overflow: hidden;
            transition: transform 0.5s;
            transform-origin: top;

            &.active {
                transform: scaleY(100%);
                overflow-y: scroll;
                bottom: 0px;
            }
        }

        hr {
            width: calc(100% - 30px);
        }

        .item {
            margin-bottom: 15px;
        }

        .toggle-button {
            text-align: center;

            .current-date {
                margin-bottom: 15px;

                .date {
                    font-family: 'typewriter';
                    font-size: 2em;
                }
            }
        }

        .date-selector {
            position: absolute;
            z-index: 50;
        }

        .search-bar {
            margin-top: 15px;

            input {
                width: 100%;
                box-sizing: border-box;
            }
        }

        .filter-section {
            .header {
                font-weight: bold;
                display: flex;
                justify-content: space-between;

                .clear-filter {
                    font-weight: normal;
                    color: $red-2;
                    font-size: 0.8em;
                    cursor: pointer;
                    transition: color 0.2s;

                    &:hover {
                        color: $dark-gray;
                    }
                }
            }

            .filters {
                padding-top: 7px;

                .filter {
                    margin-bottom: 7px;
                    border-bottom: 2px solid transparent;
                    background-color: transparent;
                    padding-bottom: 3px;
                    padding-top: 3px;
                    transition: border 0.2s;
                    cursor: pointer;

                    &:hover,
                    &.active {
                        border-bottom: 2px solid $red-2;
                    }
                }
            }
        }
    }
}