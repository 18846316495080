@import './Colors.scss';

.production {
    background-image: url('https://firebasestorage.googleapis.com/v0/b/vanguard-gg.appspot.com/o/home-page%2Fef29c3074add36ee87701a27def14caa.jfif?alt=media&token=ec6f6043-da66-4c08-b82c-d621ea20ef4d');
    background-position: right;
    height: 25vh;

    .shade {
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6);

        .content-gradient {
            height: 100%;
            background-image: linear-gradient(to right, rgba(175, 31, 40, 1), rgba(255, 0, 0, 0));
            color: $white;
            padding-left: 30px;

            .content {
                height: 100%;

                h1 {
                    margin: 0px;
                    font-family: 'typewriter';
                    font-size: 6em;

                    @media only screen and (max-width: 1024px) {
                        font-size: 3em;
                    }
                }

                .tagline {
                    color: $dark-gray;
                    margin-top: -7px;
                    font-size: 1.3em;
                    color: $white;
                    font-family: 'regular';
                }

                .production-items {
                    margin-top: -20px;
                    min-height: 60px;

                    @media only screen and (max-width: 1024px) {
                        margin-top: 0px;
                    }

                    .item {
                        font-family: 'typewriter';
                        font-size: 3em;
                        position: absolute;
                        opacity: 0;
                        animation: cycleWord 40s linear infinite 6s;
                        overflow: hidden;
                        color: $dark-gray;

                        @for $i from 0 through 8 {
                            &:nth-child(#{$i}) {
                                animation-delay: #{$i*5-5}s;
                            }
                        }
                    }
                }
            }
        }
    }
}

@keyframes cycleWord {
    0% {
        opacity: 0;
    }

    2% {
        opacity: 0;
        transform: translateY(-30px);
    }

    3% {
        opacity: 1;
        transform: translateY(0px);
    }

    10% {
        opacity: 1;
        transform: translateY(0px);
    }

    12% {
        opacity: 0;
        transform: translateY(30px);
    }

    80% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}