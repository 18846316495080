@import '../styles/Colors.scss';

.admin-page {
    display: flex;

    .menu {
        width: 10vw;
        height: calc(100vh - 100px);
        background-color: $red-2;
        box-shadow: 3px 5px 10px rgba(22, 22, 22, 0.7);
        padding: 25px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .logout {
            button {
                width: 100%;
            }
        }

        .item {
            font-family: 'typewriter';
            color: $white;
            font-size: 2.5em;
            text-transform: uppercase;
            text-align: right;
            transition: color 0.3s, border 0.3s;
            cursor: pointer;
            border-bottom: 3px solid transparent;

            &.active {
                color: lighten($dark-gray, 10%);
                border-bottom: 3px solid lighten($dark-gray, 10%);
            }

            &:hover {
                color: lighten($dark-gray, 10%);
            }
        }
    }

    .content {
        padding: 30px;

        .products {
            margin-top: 15px;
            display: flex;
            flex-wrap: wrap;
            gap: 30px;

            .filter-container {
                display: flex;
                flex-direction: column;
                gap: 30px;
            }

            .controls {
                display: flex;
                justify-content: space-between;
            }

            .products-list {
                height: 80vh;
                overflow-y: scroll;
                margin-top: 25px;
                width: 50vw;

                .product-row {
                    display: flex;
                    justify-content: space-between;
    
                    padding-top: 7px;
                    padding-bottom: 7px;
                    padding-left: 15px;
    
                    border-bottom: 1px solid $red-2;
    
                    .item {
                        width: 25%;

                        .button {
                            font-size: 0.9em;
                            padding: 4px 12px;
                        }

                        .edit-button {
                            margin-right: 5px;
                        }
                    }
                }
            }
        }
    }
}