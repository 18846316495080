@import './Colors.scss';

.rental-contact-info {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 50px);
    flex-direction: column;

    .create-request {
        margin-top: 15px;
        margin-bottom: 30px;
    }

    .panel {
        background-color: white;
        border-radius: 7px;
        $triangle-size: 4px;
        $stop1: $triangle-size * 1.42;
        $stop2: $triangle-size * 0.7;
        $stop1r: $stop1 +0.01;
        $stop2r: $stop2 +0.01;
        background: linear-gradient(135deg, transparent #{$stop1}, white #{$stop1r}) top left,
        linear-gradient(45deg, white #{$stop2}, transparent #{$stop2r}) top left,
        linear-gradient(135deg, white #{$stop2}, transparent #{$stop2r}) bottom left,
        linear-gradient(45deg, transparent #{$stop1}, white #{$stop1r}) bottom left;
        background-repeat: repeat-x;
        background-size: ($triangle-size * 2) $triangle-size;
        padding: $triangle-size 0;
        filter: drop-shadow(3px 3px 3px rgba(#000, 0.3));

        @media only screen and (max-width: 1024px) {
            margin-top: 40px;
        }

        &-content {
            padding: 15px 50px;
            background-color: white;
            height: 650px;
            width: 275px;
            display: flex;
            flex-direction: column;
            align-items: center;
            overflow: hidden;
            overflow-y: scroll
        }

        form {
            width: 100%;

            .row {
                display: flex;

                h4 {
                    margin-top: 15px;
                    margin-bottom: 15px;
                    font-family: 'typewriter';
                    text-transform: uppercase;
                    font-size: 1.7em;
                }

                .field {
                    display: flex;
                    flex-direction: column;
                    width: 100%;

                    .label-group {
                        display: flex;
                        align-items: flex-end;

                        .error {
                            margin-left: 3px;
                            color: $red-2;
                            font-size: 0.7em;
                            margin-bottom: 2px;
                        }
                    }

                    &.zip {
                        input {
                            width: calc(100% - 25px);
                        }
                    }

                    &.state {
                        width: auto;

                        select {
                            max-width: 75px;
                        }
                    }

                    input {
                        padding: 7px 12px;
                    }

                    select {
                        padding: 6px 12px;
                        margin-right: 15px;
                    }
                }
            }
        }

        
    }
    
}