.checkout-page {
    height: calc(100vh - 50px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .panel {
        background-color: white;
        border-radius: 7px;
        $triangle-size: 4px;
        $stop1: $triangle-size * 1.42;
        $stop2: $triangle-size * 0.7;
        $stop1r: $stop1 +0.01;
        $stop2r: $stop2 +0.01;
        background: linear-gradient(135deg, transparent #{$stop1}, white #{$stop1r}) top left,
        linear-gradient(45deg, white #{$stop2}, transparent #{$stop2r}) top left,
        linear-gradient(135deg, white #{$stop2}, transparent #{$stop2r}) bottom left,
        linear-gradient(45deg, transparent #{$stop1}, white #{$stop1r}) bottom left;
        background-repeat: repeat-x;
        background-size: ($triangle-size * 2) $triangle-size;
        padding: $triangle-size 0;
        filter: drop-shadow(3px 3px 3px rgba(#000, 0.3));

        @media only screen and (max-width: 1024px) {
            margin-top: 40px;
        }

        &-content {
            padding: 15px 50px;
            background-color: white;
            height: 600px;
            width: 275px;
            display: flex;
            flex-direction: column;
            align-items: center;
            overflow: hidden;
            overflow-y: scroll
        }

        .mobile-navbar {
            top:-60px;
            left: -13px;
        }

        .content {
            left: 0px;
            top: -33px;

            @media only screen and (max-width: 1024px) {
                box-shadow: 3px 5px 15px rgba(22, 22, 22, 0.3);
                overflow: visible;
            }

            &.active {
                max-height: 125px;
            }
        }

        .header {
            margin: 0px;
            font-family: 'typewriter';
            text-transform: uppercase;
            font-size: 2.5em;
        }

        .cart-contents {
            .item {
                display: flex;
                justify-content: space-between;
                margin-bottom: 7px;
                border-bottom: 0.5px dashed #ccc;
                padding-top: 20px;
                padding-bottom: 3px;

                .name {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 255px;
                }

                .quantity-dropdown {
                    margin-top: -10px;

                    select {
                        width: 70px;
                    }

                    input {
                        width: 58px;
                    }
                }
            }
        }
    }

    .cart-total {
        margin-top: 15px;
        font-size: 1.2em;
    }
}