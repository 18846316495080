@import './Colors.scss';

.label-group {
    display: flex;
    align-items: center;

    .error {
        margin-left: 3px;
        font-size: 0.8em;
        color: $red-2;
    }
}