@import './Colors.scss';

.edit-filters {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .header {
        h1 {
            margin: 0px;
            font-family: 'typewriter';
            text-transform: uppercase;
            font-size: 2em;
        }
    }

    .add-new {
        display: flex;
        align-items: flex-start;
        gap: 7px;
    }

    .filters {
        .filter {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid rgba(22, 22, 22, 0.1);
            padding-top: 7px;
            padding-bottom: 7px;

            .delete {
                color: $red-2;
                font-size: 1.2em;
                cursor: pointer;
            }
        }
    }
}