.equipment-container {
    display: flex;
    height: 100%;

    @media only screen and (max-width: 1024px) {
        flex-direction: column;
    }

    .filters {
        padding: 15px;

        @media only screen and (max-width: 1024px) {
            padding: 0px;
        }
    }

    .content {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-height: calc(100vh - 50px);
        overflow-y: scroll;

        .intro {
            width: 100%;
        }

        .equipment-rental {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            padding-top: 60px;
            width: 100%;
            gap: 15px;
    
            @media only screen and (max-width: 1024px) {
                flex-direction: column;
                align-items: center;
            }
        
            .product {
                padding: 0px;
                margin-bottom: 15px;
            }
    
            .no-results-found {
                font-family: 'typewriter';
                text-transform: uppercase;
                font-size: 2em;
                opacity: 0.5;
                text-align: center;
                width: 100%;
            }
        }
    }
}