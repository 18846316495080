@import './Colors.scss';

.rental {
    background-image: url("https://firebasestorage.googleapis.com/v0/b/vanguard-gg.appspot.com/o/home-page%2Ff02c90d095606d73aa6489e7ac55e372.jfif?alt=media&token=0bcc4f3f-aa48-4341-9dde-d4753dd645c1");
    background-position: center;

    .shade {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        background-color: rgba(255, 255, 255, 0.93);
        height: 100%;
        padding-top: 30px;
        padding-bottom: 30px;
        padding-right: 15px;
        padding-left: 15px;

        .header {
            font-family: 'typewriter';
            text-align: center;

            h1 {
                font-size: 4em;
                margin: 0px;
            }
        }

        .items {
            display: flex;
            gap: 50px;
            justify-content: space-around;

            .section {
                width: 50%;
                background-color: rgba(22, 22, 22, 0.1);
                padding: 15px;
                margin-top: 30px;
                margin-bottom: 30px;

                h1 {
                    font-family: 'typewriter';
                    text-transform: uppercase;
                    font-size: 2.5em;
                    text-align: center;
                }
            }

            .list {

                li {
                    margin: 20px;
                    font-size: 1.3em;
                    color: $red-2;
                    cursor: pointer;
                    transition: color 0.2s, transform 0.3s;

                    &:hover {
                        color: $dark-gray;
                        transform: translateX(15px);
                    }
                }
            }
        }

        .footer {
            text-align: center;
            font-size: 1.3em;
            display: flex;
            justify-content: center;

            h4 {
                transition: color 0.2s, transform 0.3s;
            }

            .arrow {
                svg {
                    margin-top: 4px;
                    color: transparent;
                    transition: color 0.2s, transform 0.3s;
                }
            }

            h4 {
                margin: 0px;
                cursor: pointer;

            }

            &:hover {

                h4 {
                    color: $red-2;
                    transform: translateX(15px);
                }


                .arrow {
                    svg {
                        color: $red-2;
                        transform: translateX(15px);
                    }
                }
            }
        }

        .mobile-footer {
            margin-top: 15px;
            font-size: 1.3em;
            text-align: center;
            ;
        }
    }
}