@import '../styles/Colors.scss';

.equipment-requests {
    .section {
        margin-bottom: 30px;

        h4 {
            font-family: 'typewriter';
            text-transform: uppercase;
            font-size: 2em;
            margin: 0px;
        }
    }

    .request {
        display: flex;
        border-bottom: 1px solid $red-2;
        max-width: 75vw;
        padding-left: 15px;
        transition: background-color 0.3s;
        cursor: pointer;

        &:hover {
            background-color: rgba(22, 22, 22, 0.3);
        }

        .field {
            margin-right: 15px;
            width: 200px;
            padding-top: 7px;
            padding-bottom: 7px;
        }
    }
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal {
    background-color: $white;
    position: absolute;
    width: 25vw;
    left: 37vw;
    height: 60vh;
    top: 20vh;
    border-radius: 7px;
    padding: 30px;

    .modal-contents {
        h1, h4 {
            margin: 0px;
        }

        .address {
            font-size: 0.8em;
        }

        .name-date {
            display: flex;
            justify-content: space-between;

            .end-date {
                text-align: right;
                font-size: 0.8em;
            }
        }

        .cart-contents {
            .item {
                display: flex;

                .thumbnail {
                    margin-right: 15px;

                    img {
                        max-width: 100px;
                    }
                }
            }
        }

        .footer {
            position: absolute;
            bottom: 30px;
            right: 30px;
            text-align: right;
            width: calc(100% - 60px);

            h3 {
                margin: 0px;
                margin-bottom: 15px;
            }

            .buttons {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .approve {
                    margin-right: 7px;
                }
    
                .button {
                    padding: 7px 15px;
                    background-color: transparent;
                    font-family: 'typewriter';
                    text-transform: uppercase;
                    font-size: 1.2em;
                    cursor: pointer;
            
                    background: none;
                    border: 0;
                    box-sizing: border-box;
            
                    box-shadow: inset 0 0 0 2px $dark-gray;
                    color: $dark-gray;
                    font-weight: 700;
            
                    position: relative;
                    vertical-align: middle;
            
                    &::before,
                    &::after {
                        box-sizing: inherit;
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 100%;
                    }
            
                    transition: color 0.25s, background-color 0.25s;
            
                    &::before,
                    &::after {
                        // Set border to invisible, so we don't see a 4px border on a 0x0 element before the transition starts
                        border: 2px solid transparent;
                        width: 0;
                        height: 0;
                    }
            
                    // This covers the top & right borders (expands right, then down)
                    &::before {
                        top: 0;
                        left: 0;
                    }
            
                    // And this the bottom & left borders (expands left, then up)
                    &::after {
                        bottom: 0;
                        right: 0;
                    }
            
                    &:hover {
                        background-color: $red-2;
                        color: $white;
                    }
            
                    // Hover styles
                    &:hover::before,
                    &:hover::after {
                        width: 100%;
                        height: 100%;
                    }
            
                    &:hover::before {
                        border-top-color: $red-2; // Make borders visible
                        border-right-color: $red-2;
                        transition:
                            width 0.25s ease-out, // Width expands first
                            height 0.25s ease-out 0.25s; // And then height
                    }
            
                    &:hover::after {
                        border-bottom-color: $red-2; // Make borders visible
                        border-left-color: $red-2;
                        transition:
                            border-color 0s ease-out 0.5s, // Wait for ::before to finish before showing border
                            width 0.25s ease-out 0.5s, // And then exanding width
                            height 0.25s ease-out 0.75s; // And finally height
                    }
            
                    &.primary {
                        background-color: $red-2;
                        color: $white;
                        letter-spacing: 3px;
                        box-shadow: none;
            
                        &:hover::before {
                            border-top-color: $dark-gray; // Make borders visible
                            border-right-color: $dark-gray;
                        }
                
                        &:hover::after {
                            border-bottom-color: $dark-gray; // Make borders visible
                            border-left-color: $dark-gray;
                        }
                    }
                }
            }
        }
    }
}