@import './Colors.scss';

.email-recipients {
    .add-new {
        display: flex;
        align-items: center;

        button {
            margin-left: 7px;
        }
    }

    .recipients {
        display: flex;
        flex-wrap: wrap;
        margin-top: 30px;

        .recipient {
            display: flex;
            align-items: center;
            background-color: rgba(22, 22, 22, 0.1);
            justify-content: space-between;
            padding: 7px 15px;
            margin-right: 7px;
            margin-bottom: 7px;

            .remove {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 3px;
                cursor: pointer;

                svg {
                    font-size: 1.5em;
                    color: $red-2;
                }
            }
        }
    }
}