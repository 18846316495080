@import './Colors.scss';

.staffing {
    padding: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @media only screen and (max-width: 1024px) {
        flex-direction: column;
        padding: 15px;
    }

    .header {
        max-width: 30vw;
        font-family: 'typewriter';
        font-size: 2vw;
        text-align: right;

        @media only screen and (max-width: 1024px) {
            font-size: 1.5em;
            max-width: 100%;
            text-align: center;
        }

        h1 {
            margin: 0px;
        }
    }

    .side-image {
        margin-left: 30px;

        @media only screen and (max-width: 1024px) {
            margin: 0px;
        }

        img {
            max-width: 20vw;
            clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 10% 50%, 0% 0%);

            @media only screen and (max-width: 1024px) {
                clip-path: polygon(100% 0, 100% 75%, 50% 100%, 0 75%, 0 0, 50% 25%);
                max-width: 100%;
            }
        }
    }

    .content {
        width: 50vw;
        min-height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 30px;

        @media only screen and (max-width: 1024px) {
            width: 100%;
            text-align: center;
        }

        .label h1 {
            margin: 0px;
        }

        .typewriter {
            display: flex;
            align-items: center;

            @media only screen and (max-width: 1024px) {
                align-items: flex-start;
                text-align: left;
                min-height: 175px;
            }

            .Typewriter__wrapper {
                color: $dark-gray;
                margin: 0px;
                font-size: 3em;
                font-family: 'typewriter';
            }

            .Typewriter__cursor {
                color: $red-2;
                font-size: 3em;
                margin-top: -10px;
            }
        }
    }
}