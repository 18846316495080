@import './Colors.scss';

.careers-page {
    display: flex;
    flex-direction: column;
    align-items: center;

    .panel {
        background-color: white;
        border-radius: 3px;
        box-shadow: 3px 5px 15px rgba(22, 22, 22, 0.3);
        display: flex;
        flex-direction: column;
        padding: 30px;
    }

    .header {
        font-family: 'typewriter';
        text-transform: uppercase;
        font-size: 1.6em;
    }

    .content {
        max-width: 40vw;

        @media only screen and (max-width: 1024px) {
            max-width: 90vw;
        }

        .row {
            display: flex;
            margin-bottom: 15px;

            .field {
                width: 100%;

                .label-group {
                    display: flex;
                    align-items: center;

                    .error {
                        margin-left: 3px;
                        font-size: 0.8em;
                        color: $red-2;
                    }
                }

                .control {
                    input {
                        width: calc(100% - 30px);
                    }

                    select {
                        width: 100%;
                    }
                }
            }

            .submit-button {
                width: 100%;

                button {
                    width: 100%;
                }
            }
        }
    }

    .overlay {
        position: fixed;
        width: 100vw;
        height: 100vh;
        background-color: rgba(22, 22, 22, 0.9);

        justify-content: center;
        align-items: center;

        display: none;
        opacity: 0;

        transition: opacity 0.3s 0.3s;

        &.visible {
            display: flex;
            opacity: 1;
        }

        .panel {
            max-width: 25vw;
        }
    }
}