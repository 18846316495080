@import './Colors.scss';

.equipment-rental-tutorial {
    background-color: white;
    padding: 30px;
    max-width: 70%;
    margin-top: 30px;
    box-shadow: 3px 5px 15px rgba(22, 22, 22, 0.3);
    position: relative;

    .close-button {
        position: absolute;
        right: 15px;
        top: 15px;

        font-size: 2em;
        color: $red-2;
        cursor: pointer;
    }

    .header {
        font-family: 'typewriter';
        text-transform: uppercase;
        font-size: 1.5em;
        
        h1 {
            margin: 0px;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        overflow-y: visible;

        p {
            margin: 7px;
        }
    }
}