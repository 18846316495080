@import './Colors.scss';

.confirmation {
    text-align: center;
    height: calc(100vh - 50px);
    display: flex;
    justify-content: center;
    align-items: center;

    .panel {
        max-width: 300px;
        height: 650px;
        box-shadow: 3px 5px 15px rgba(22, 22, 22, 0.3);
        background-color: white;
        padding: 30px;

        div {
            margin-bottom: 15px;

            .click {
                color: $red-1;
                cursor: pointer;
            }
        }
    }

    h1 {
        margin: 0px;
        margin-bottom: 30px;
    }
}