.product {
    display: flex;
    padding: 15px;
    justify-content: center;

    @media only screen and (max-width: 1024px) {
        flex-direction: column;
        padding: 0px;
        align-items: center;
    }

    .content {
        display: flex;

        @media only screen and (max-width: 1024px) {
            flex-direction: column;
            margin-top: 50px;
        }
    }

    .images {
        @media only screen and (max-width: 1024px) {
            margin-left: 30px;
            margin-right: 30px;
            text-align: center;
        }

        img {
            max-width: 400px;

            @media only screen and (max-width: 1024px) {
                max-width: 300px;
            }
        }
    }

    .body {
        max-width: 33vw;
        min-width: 640px;
        margin-left: 30px;
        margin-right: 30px;

        @media only screen and (max-width: 1024px) {
            max-width: 100%;
        }
    }

    .rental-widget {
        border: 2px solid rgba(22, 22, 22, 0.3);
        border-radius: 20px;
        padding: 30px;
        max-width: 250px;

        @media only screen and (max-width: 1024px) {
            margin-bottom: 5vh;
        }

        .rental-navbar {
            .mobile-navbar {
                left: 0px;
            }

            .content {
                left: 0px;
                margin-top: 10px;
                overflow: visible;

                @media only screen and (max-width: 1024px) {
                    box-shadow: 3px 5px 15px rgba(22, 22, 22, 0.3);
                }

                &.active {
                    max-height: 125px;
                }
            }
        }

        .header {
            font-family: 'typewriter';
            text-transform: uppercase;
            font-size: 1.3em;
            text-align: center;
            
            h1 {
                margin: 0px;
            }
        }
    }
}