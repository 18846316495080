@import './Colors.scss';

.cart {
    
    position: fixed;
    bottom: 75px;
    right: 75px;
    filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.8));
    z-index: 10;

    @media only screen and (max-width: 1024px) {
        bottom: 25px;
    right: 25px;
    }

    .contents {
        position: fixed;
        bottom: 100px;
        right: 20px;
    }

    .cart-button {
        text-align: right;
        cursor: pointer;

        .shopping-cart {
            background-color: $red-2;
            width: 75px;
            height: 75px;
            border-radius: calc(75px/2);
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid $dark-gray;

            svg {
                color: $white;
                font-size: 2em;
            }
        }
    }
}