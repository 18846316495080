@import './Colors.scss';

.user-management {
    width: 50vw;
    .user-table {
        width: 100%;

        th, td {
            text-align: left;
            border-bottom: 1px solid $red-2;
            padding: 7px 15px;
        }
    }
}