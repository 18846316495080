@import './Colors.scss';

.cta {
    background-color: $dark-gray;
    color: $white;
    font-family: 'typewriter';
    text-align: center;
    padding: 15px 30px;

    .header {
        display: flex;
        justify-content: center;

        h1 {
            margin: 0px;
            font-size: 3em;
            cursor: pointer;

            @media only screen and (max-width: 1024px) {
                font-size: 1.5em;
            }

            &:after {
                content: '';
                display: block;
                margin: auto;
                height: 3px;
                width: 0px;
                background: transparent;
                transition: width .3s ease, background-color .3s ease;
            }

            &:hover:after {
                width: 100%;
                background: $red-2;
            }
        }
    } 
}