@import '../styles//Colors.scss';

.hero-image {
    background-image: url('https://firebasestorage.googleapis.com/v0/b/vanguard-gg.appspot.com/o/home-page%2F462153fe43f2ac5603944dc94adf7bc1-Recovered.jpg?alt=media&token=844fa381-8aba-4881-ac3d-45ef0c120b2c');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 600px;

    .shade {
        display: flex;
        align-items: flex-end;
        padding-left: 30px;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6);

        @media only screen and (max-width: 1024px) {
            align-items: center;
        }
    }

    .typewriter {
        padding-bottom: 30px;

        .Typewriter__wrapper {
            color: $white;
            margin: 0px;
            font-size: 6em;
            font-family: 'typewriter';

            @media only screen and (max-width: 1024px) {
                font-size: 4em;
            }
        }

        .Typewriter__cursor {
            color: $red-2;
            font-size: 6em;
            margin-top: -10px;

            @media only screen and (max-width: 1024px) {
                font-size: 4em;
            }
        }
    }
}