@import './Colors.scss';

.about-page {
    .hero {
        background-image: url('https://firebasestorage.googleapis.com/v0/b/vanguard-gg.appspot.com/o/about-page%2F2b01bda66d196471dd2fd8697fabe863.jfif?alt=media&token=efc9bdff-259e-4b4a-bc69-fb897a2f2ca9');
        background-position: 50% 35%;
        height: 50vh;
        color: $white;

        .shade {
            height: 100%;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.4);
            display: flex;
            flex-direction: column;
            justify-content: center;

            .content {
                padding: 30px;

                h1 {
                    font-family: 'typewriter';
                    text-transform: uppercase;
                    margin: 0px;
                    font-size: 4em;

                    @media only screen and (max-width: 1024px) {
                        font-size: 3em;
                    }
                }

                .Typewriter__wrapper {
                    color: $white;
                    margin: 0px;
                    font-size: 4em;
                    font-family: 'typewriter';

                    @media only screen and (max-width: 1024px) {
                        font-size: 3em;
                    }
                }

                .Typewriter__cursor {
                    color: $red-2;
                    font-size: 4em;
                    margin-top: -10px;

                    @media only screen and (max-width: 1024px) {
                        font-size: 3em;
                    }
                }
            }
        }
    }

    .mission-statement {
        display: flex;
        flex-direction: column;
        padding: 30px;

        .section {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 15px;

            @media only screen and (max-width: 1024px) {
                flex-wrap: wrap;

                &.even {
                    flex-direction: row-reverse;
                    align-items: center;
                }
            }

            .side-image {
                max-width: 50%;
                display: flex;
                justify-content: flex-end;
                margin-right: 30px;
                margin-left: 30px;

                @media only screen and (max-width: 1024px) {
                    max-width: 100%;
                }

                img {
                    margin-right: 30px;
                    width: 300px;
                    clip-path: polygon(30% 0, 100% 0, 100% 70%, 70% 100%, 0 100%, 0 30%);
                    border-top: 5px solid $red-2;
                    border-bottom: 5px solid $red-2;

                    @media only screen and (max-width: 1024px) {
                        width: 100%;
                        margin-right: 0px;
                    }
                }
            }

            .content {
                width: 50%;
                text-align: left;
                display: flex;
                flex-direction: column;
                justify-content: center;
                font-size: 1.2em;
                height: 225px;
                padding: 20px;
                border-radius: 125px;

                background-image: linear-gradient(to left, transparent, rgba(22, 22, 22, 0.05));

                p {
                    margin: 0px;
                    margin-bottom: 10px;
                    max-width: 50%;

                    @media only screen and (max-width: 1024px) {
                        max-width: 100%;
                    }
                }

                @media only screen and (max-width: 1024px) {
                    width: 100%;
                }
            }

            &.even {
                .side-image {
                    justify-content: flex-start;

                    img {
                        clip-path: polygon(0 0, 70% 0, 100% 30%, 100% 100%, 30% 100%, 0 70%);
                        margin-right: 0px;
                        margin-left: 30px;

                        @media only screen and (max-width: 1024px) {
                            margin-left: 0px;
                        }
                    }
                }

                .content {
                    text-align: right;
                    align-items: flex-end;

                    background-image: linear-gradient(to right, transparent, rgba(22, 22, 22, 0.05))
                }
            }
        }
    }

    .by-the-numbers {
        min-height: 30vh;
        background-image: url('https://firebasestorage.googleapis.com/v0/b/vanguard-gg.appspot.com/o/about-page%2F11195300a81db51706232f5e224f233e.jpg?alt=media&token=a5306745-62c9-4483-aa19-6f9d357c8462');
        background-position: center;
        background-size: cover;
        border-top: 5px solid $red-2;

        .shade {
            height: 100%;
            background-image: linear-gradient(to right, $white, rgba(248, 240, 227, 0.6));
            padding-bottom: 30px;

            .header {
                padding: 30px;
                text-transform: uppercase;
                font-family: 'typewriter';

                h1,
                h4 {
                    margin: 0px;
                }

                h1 {
                    font-size: 4em;
                }

                h4 {
                    font-size: 2em;
                }
            }

            .content {
                display: flex;
                justify-content: space-evenly;
                flex-wrap: wrap;

                .item-container {
                    width: 25%;
                    display: flex;
                    justify-content: center;
                    margin-bottom: 30px;

                    @media only screen and (max-width: 1024px) {
                        width: 100%;
                    }

                    .item {
                        max-width: 250px;
                        text-align: center;

                        @media only screen and (max-width: 1024px) {
                            text-align: left;
                        }

                        .number {
                            font-size: 3em;
                            font-weight: bold;
                            font-family: 'typewriter';
                            
                            .plus {
                                color: $red-2;
                                padding-left: 5px;
                            }
                        }
                    }
                }
            }
        }
    }
}