@use './styles/Colors.scss' as *;
@import './styles/Fonts.scss';

.App {
    width: 100%;
    height: 100%;
    background-color: $white;

    color: $dark-gray;
    font-family: 'regular';

    .input {
        padding: 10px 15px;
        border: 1px solid $dark-gray;
        border-radius: 3px;
        font-family: 'regular';

        &:focus-visible {
            outline: none;
            border: 1px solid $red-2;
        }
    }

    .button {
        padding: 7px 15px;
        background-color: transparent;
        font-family: 'typewriter';
        text-transform: uppercase;
        font-size: 1.2em;
        cursor: pointer;

        background: none;
        border: 0;
        box-sizing: border-box;

        box-shadow: inset 0 0 0 2px $dark-gray;
        color: $dark-gray;
        font-weight: 700;

        position: relative;
        vertical-align: middle;

        &::before,
        &::after {
            box-sizing: inherit;
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
        }

        transition: color 0.25s, background-color 0.25s;

        &::before,
        &::after {
            // Set border to invisible, so we don't see a 4px border on a 0x0 element before the transition starts
            border: 2px solid transparent;
            width: 0;
            height: 0;
        }

        // This covers the top & right borders (expands right, then down)
        &::before {
            top: 0;
            left: 0;
        }

        // And this the bottom & left borders (expands left, then up)
        &::after {
            bottom: 0;
            right: 0;
        }

        &:hover {
            background-color: $red-2;
            color: $white;
        }

        // Hover styles
        &:hover::before,
        &:hover::after {
            width: 100%;
            height: 100%;
        }

        &:hover::before {
            border-top-color: $red-2; // Make borders visible
            border-right-color: $red-2;
            transition:
                width 0.25s ease-out, // Width expands first
                height 0.25s ease-out 0.25s; // And then height
        }

        &:hover::after {
            border-bottom-color: $red-2; // Make borders visible
            border-left-color: $red-2;
            transition:
                border-color 0s ease-out 0.5s, // Wait for ::before to finish before showing border
                width 0.25s ease-out 0.5s, // And then exanding width
                height 0.25s ease-out 0.75s; // And finally height
        }

        &.primary {
            background-color: $red-2;
            color: $white;
            letter-spacing: 3px;
            box-shadow: none;

            &:hover::before {
                border-top-color: $dark-gray; // Make borders visible
                border-right-color: $dark-gray;
            }
    
            &:hover::after {
                border-bottom-color: $dark-gray; // Make borders visible
                border-left-color: $dark-gray;
            }
        }
    }
}