@import './Colors.scss';

.container {
    max-height: 0px;
    overflow: hidden;
    transform-origin: bottom right;
    transform: scaleX(0%);
    transition: all 0.4s ease-in-out;

    &.expanded {
        transform: scaleX(100%);
        opacity: 1;
        max-height: 460px;
        min-width: 300px;
    }

    .cart-contents {
        padding: 15px;
        padding-top: 30px;
        background-color: white;
        border-radius: 15px;
        height: 400px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .checkout {
            width: 100%;
        }

        .subtotal {
            text-align: center;
            font-weight: bold;
            margin-bottom: 15px;

            .price {
                color: $red-2;
            }

            .label {
                font-size: 0.75em;
            }
        }

        .items {
            margin-bottom: 15px;

            .item {
                display: flex;
                padding-top: 7px;
                padding-bottom: 7px;
                border-bottom: 1px solid rgba(22, 22, 22, 0.1);
                gap: 20px;

                .quantity {
                    text-align: right;
                    margin-right: 7px;
                    width: 50px;
                }

                .name {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 255px;
                }
            }
        }
    }
}