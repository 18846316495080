@import '../styles/Colors.scss';

.edit-product {
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    .field {
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;
        width: 40vw;


        .label-group {
            display: flex;

            .error {
                color: $red-1;
                font-size: 0.8em;
                display: inline;
            }
        }

        .existing img {
            width: 150px;
        }

        input {
            width: calc(100% - 30px);
        }

        .description-editor {
            width: 40vw;
        }
    }

    .submit-button {
        margin-right: 5px;
    }
}