@import './Colors.scss';

.navbar {
    position: sticky;
    z-index: 100;
    top: 0px;
    height: 50px;

    padding-left: 15px;
    padding-right: 15px;

    background-color: $dark-gray;
    opacity: 0.9;
    color: $white;
    filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.8));

    display: flex;
    align-items: center;
    justify-content: space-between;

    .mobile-nav-items {
        position: absolute;
        left: 0px;
        top: 50px;
        background-color: $dark-gray;
        width: 100%;
        max-height: 0px;
        overflow: hidden;
        transition: max-height 0.3s;

        &.active {
            max-height: 180px;
        }

        .item {
            padding: 7px;
            text-align: center;
            border-bottom: 1px solid black;
        }
    }

    .nav-section {
        display: flex;
        align-items: center;
        height: 90%;

        .item {
            display: flex;
            align-items: center;
            margin-left: 15px;
            cursor: pointer;
            height: 100%;

            &.logo {
                margin-top: -5px;
                margin-right: 5px;
                margin-left: 0px;
            }

            .content {
                padding-left: 10px;
                padding-right: 10px;
                border-bottom: 2px solid transparent;
                transition: border-bottom 0.3s 0.1s, background-color 0.3s;
                background-color: transparent;
                height: 100%;
                display: flex;
                align-items: center;

                &.active {
                    border-bottom: 2px solid $red-2;
                    background-color: darken($dark-gray, 20%);
                }

                &:hover {
                    background-color: darken($dark-gray, 20%);
                }
            }
        }
    }


}