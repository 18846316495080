.login-page {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .panel {
        background-color: white;
        padding: 30px;
        min-height: 400px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: 3px 5px 15px rgba(22, 22, 22, 0.3);
        width: 225px;

        .header {
            margin-bottom: 30px;

            h1 {
                margin: 0px;
                text-transform: uppercase;
                font-family: 'typewriter';
                font-size: 3.5em;
                text-align: center;
            }
        }
    }
}