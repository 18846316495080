@import '../styles/Colors.scss';

.rent-product {
    text-align: center;

    .available-stock {
        font-style: italic;
    }

    .quantity {
        input {
            width: calc(100% - 35px);
            text-align: center;
        }
    }

    .continue {
        margin-top: 5px;

        button {
            width: 100%;
        }
    }

    .date-range {
        .edit-button {
            font-size: 0.8em;
            cursor: pointer;
            color: $red-2;
            transition: color 0.2s;

            &:hover {
                color: darken($red-2, 15%);
            }
        }
    }
}