@import '../styles/Colors.scss';

.solutions {
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    min-height: 625px;

    padding-right: 50px;

    @media only screen and (max-width: 1024px) {
        flex-direction: column;
        padding-right: 15px;
    }

    .side-image {
        align-self: center;
        max-width: 50%;
        clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%);

        img {
            width: 100%;
        }
    }

    .content {
        font-family: 'typewriter';
        font-size: 2em;
        width: 50%;
        margin-top: 15px;

        @media only screen and (max-width: 1024px) {
            order: 1;
            width: 100%;
        }

        h1 {
            margin-top: 0px;
        }

        .mobile-items {
            display: flex;
            flex-direction: column;
            width: 100%;
            text-align: right;

            .item {
                margin-bottom: 15px;

                .header {
                    font-size: 1.5em;
                    text-transform: uppercase;
                }
    
                .child {
                    font-family: 'regular';
                    font-size: 0.7em;
                }
            }
        }

        .items {
            margin-top: 25px;
            font-family: 'typewriter';
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            .item {
                text-align: right;
                font-size: 2em;

                padding: 7px;
                padding-right: 30px;
                width: 50%;
                cursor: pointer;

                @media only screen and (max-width: 1024px) {
                    width: 100%;
                }

                .sliding-door {
                    background-color: $white;
                    color: lighten($color: $dark-gray, $amount: 40);
                    transition: all 0.25s;
                    z-index: 2;
                    padding: 15px;
                    padding-right: 30px;
                    position: relative;
                    text-transform: uppercase;
                    display: flex;
                    justify-content: flex-end;
                    gap: 30px;
                    align-items: center;

                    &:hover {
                        background-color: $red-2;
                        color: $white;
                        transform: translateX(-50px);
                    }

                    &.active {
                        transform: translateX(-350px);
                        background-color: $red-2;
                        color: $white;
                    }
                }

                .behind-door {
                    margin-top: -106px;
                    font-family: 'regular';
                    font-size: .3em;
                    padding-right: 30px;
                    z-index: 1;
                    background-color: $gray;
                    color: $white;
                    padding: 15px;
                    padding-right: 30px;
                    height: 76px;
                }
            }
        }

        h1 {
            margin-bottom: 0px;
            text-align: right;

            @media only screen and (max-width: 1024px) {
                margin-bottom: 30px;
            }
        }

        .underline {
            background-color: $red-2;
            width: 50%;
            height: 10px;
            float: right;
            margin-top: -5px;
            border-radius: 5px;
            ;
        }
    }
}